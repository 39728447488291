var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailInfo",
                attrs: { title: "기본정보" },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-3 col-md-3 col-lg-3" },
                    [
                      _c("c-label-text", {
                        attrs: { title: "저장시설", value: "탱크1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-3 col-md-3 col-lg-3" },
                    [
                      _c("c-label-text", {
                        attrs: { title: "저장시설 위치", value: "위치1" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-3 col-md-3 col-lg-3" },
                    [
                      _c("c-label-text", {
                        attrs: { title: "총 허가량", value: "1300kg" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-6 col-sm-3 col-md-3 col-lg-3" },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "허가량 대비 재고량 비율",
                          value: "60%",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "c-table",
        {
          staticClass: "q-mt-sm",
          attrs: {
            title: "위험물 허가량 및 비율",
            tableId: "table",
            columns: _vm.grid1.columns,
            data: _vm.grid1.data,
            gridHeight: "300px",
            filtering: false,
            columnSetting: false,
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.addDangerPermit },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", { attrs: { label: "삭제", icon: "remove" } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c("c-table", {
        staticClass: "q-mt-sm",
        attrs: {
          title: "화학자재 재고량",
          tableId: "table",
          columns: _vm.grid2.columns,
          data: _vm.grid2.data,
          gridHeight: _vm.gridHeight,
          filtering: false,
          columnSetting: false,
        },
        on: { linkClick: _vm.linkClick },
      }),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }